import {COUNTRY} from '@domain/model/Site/Locale';

export const DEFAULT_COUNTRY = COUNTRY.ES;

export const COURIER = {
  GLS: 'GLS',
  MR: 'MR',
  SEUR: 'SEUR',
  IN_POST: 'IN_POST',
  EVRI: 'EVRI',
  UPS: 'UPS',
  SWISS_POST: 'SWISS_POST',
  DHL: 'DHL',
};

export const COURIER_RETURN_ENABLED: {[courier: string]: {[country: string]: boolean}} = {
  GLS: {IT: true}, // GLS return method disabled on IT temporarily. Check LK-25653 for more information.
};

export const TEXT = {
  TITLE: {id: 'myLK.checkout.returnInstructions.title'},
  SHOP_TITLE: {id: 'myLK.checkout.returnInstructionsShop.title'},
  TOAST_ERROR: {id: 'account.info.toast_error'},
  INSTRUCTION_ERROR: {id: 'myLK.returnInstructions.error'},
  STEPS: {
    ONE: {id: 'myLK.checkout.returnInstructions.step1'},
    TWO: {id: 'myLK.checkout.returnInstructions.step2'},
    THREE: {id: 'myLK.checkout.returnInstructions.step3'},
  },
  SHOP_STEPS: {
    ONE: {id: 'myLK.checkout.returnInstructionsShop.step1'},
    TWO: {id: 'myLK.checkout.returnInstructionsShop.step2'},
  },
  RETURN_BUTTON: {id: 'myLK.checkout.returnInstructions.cta'},
  RETURN_BUTTON_GB: {id: 'myLK.checkout.return_instruction_gb_cta'},
  ACCORDION: {
    SEND_PICKUP: {
      DEFAULT: {
        TITLE: {id: 'myLK.checkout.returnInstructions.accordion1.title'},
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1'},
        BUTTON: {id: 'myLK.checkout.returnInstructions.accordion1.cta'},
      },
      MR: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1_MR'},
      },
      IN_POST: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1_MR'},
      },
      SWISS_POST: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1_MR'},
      },
      DHL: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1_MR'},
      },
      SEUR: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1_SEUR'},
      },
      GLS: {
        TITLE: {id: 'myLK.checkout.returnInstructions.accordion2.title_GLS'},
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion2.content1_GLS'},
        BUTTON: {id: 'myLK.checkout.returnInstructions.accordion2.cta_GLS'},
      },
    },
    SEND_HOME: {
      DEFAULT: {
        TITLE: {id: 'myLK.checkout.returnInstructions.accordion2.title'},
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion2.content1'},
        BUTTON: {id: 'myLK.checkout.returnInstructions.accordion2.cta'},
      },
      MR: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion2.content1_MR'},
      },
      IN_POST: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion2.content1_MR'},
      },
      SWISS_POST: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion2.content1_MR'},
      },
      SEUR: {
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion2.content1_SEUR'},
      },
      GLS: {
        TITLE: {id: 'myLK.checkout.returnInstructions.accordion1.title_GLS'},
        TEXT: {id: 'myLK.checkout.returnInstructions.accordion1.content1_GLS'},
        BUTTON: {id: 'myLK.checkout.returnInstructions.accordion1.cta_GLS'},
      },
    },
  },
};
