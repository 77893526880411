const inAppBrowser =
  /FB_IAB/.test(navigator.userAgent) ||
  /FBAN/.test(navigator.userAgent) ||
  /FBAV/.test(navigator.userAgent) ||
  /Pinterest/.test(navigator.userAgent);

const iOSInAppBrowser = window.screen.height > 667 && /FBIOS/.test(navigator.userAgent);

const isAndroidInAppBrowser = inAppBrowser && window.screen.height > 667 && !/FBIOS/.test(navigator.userAgent);

/*
 Check if FB in-app browser interface height is lower than 250px and greater than 100px.
 Then the in-app browser was triggered by the FB CTA below the video.
*/
const iOSInAppBrowserVideoAd =
  iOSInAppBrowser &&
  !(window.screen.availHeight - window.innerHeight < 250 && window.screen.availHeight - window.innerHeight > 95);

const iOSInAppBrowserBannerHeight = 70;

const iOSInAppBrowserBannerTop = iOSInAppBrowserVideoAd ? iOSInAppBrowserBannerHeight : 0;

const IOS_IN_APP_BROWSER_VIDEO_AD_PADDING_BOTTOM = 75;
const IN_APP_BROWSER_MODAL_PADDING_BOTTOM = 95;

export {
  inAppBrowser,
  iOSInAppBrowser,
  isAndroidInAppBrowser,
  iOSInAppBrowserVideoAd,
  iOSInAppBrowserBannerHeight,
  iOSInAppBrowserBannerTop,
  IOS_IN_APP_BROWSER_VIDEO_AD_PADDING_BOTTOM,
  IN_APP_BROWSER_MODAL_PADDING_BOTTOM,
};
