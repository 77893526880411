const SITE = {
  BE: 'BE',
  DE: 'DE',
  ES: 'ES',
  FR: 'FR',
  IT: 'IT',
  PT: 'PT',
  UK: 'UK',
  AT: 'AT',
  NL: 'NL',
  SE: 'SE',
  CH: 'CH',
} as const;

const LANGUAGE = {
  [SITE.ES]: 'es',
  [SITE.FR]: 'fr',
  [SITE.UK]: 'en',
  [SITE.BE]: 'fr',
  LU: 'fr',
  [SITE.IT]: 'it',
  [SITE.PT]: 'pt',
  [SITE.DE]: 'de',
  [SITE.AT]: 'de',
  [SITE.NL]: 'nl',
  [SITE.SE]: 'sv',
  [SITE.CH]: 'de',
} as const;

const COUNTRY = {
  [SITE.ES]: 'ES',
  [SITE.FR]: 'FR',
  [SITE.UK]: 'GB',
  [SITE.BE]: 'BE',
  LU: 'LU',
  [SITE.IT]: 'IT',
  [SITE.PT]: 'PT',
  [SITE.DE]: 'DE',
  [SITE.AT]: 'AT',
  [SITE.NL]: 'NL',
  [SITE.SE]: 'SE',
  [SITE.CH]: 'CH',
} as const;

// Deprecated
const NATIVE_STORE_ID = {
  [SITE.ES]: 0,
  [SITE.FR]: 1,
  [SITE.UK]: 2,
  [SITE.BE]: 3,
  LU: 4,
  [SITE.IT]: 5,
  [SITE.PT]: 6,
  [SITE.DE]: 7,
  [SITE.AT]: 8,
  [SITE.NL]: 9,
  [SITE.SE]: 10,
  [SITE.CH]: 11,
} as const;
export type NativeStoreId = (typeof NATIVE_STORE_ID)[keyof typeof NATIVE_STORE_ID];

const HOSTNAME = {
  //Deprecated, maintained because some users still have this ID stored in their devices
  [NATIVE_STORE_ID.BE]: 'https://lookiero.be',
  [NATIVE_STORE_ID.LU]: 'https://lookiero.be',
  [NATIVE_STORE_ID.DE]: 'https://lookiero.de',
  [NATIVE_STORE_ID.ES]: 'https://lookiero.es',
  [NATIVE_STORE_ID.FR]: 'https://lookiero.fr',
  [NATIVE_STORE_ID.IT]: 'https://lookiero.it',
  [NATIVE_STORE_ID.PT]: 'https://lookiero.pt',
  [NATIVE_STORE_ID.UK]: 'https://lookiero.co.uk',
  [NATIVE_STORE_ID.AT]: 'https://lookiero.at',
  [NATIVE_STORE_ID.NL]: 'https://lookiero.nl',
  [NATIVE_STORE_ID.SE]: 'https://lookiero.se',
  [NATIVE_STORE_ID.CH]: 'https://lookiero.ch',

  [SITE.BE]: 'https://lookiero.be',
  [SITE.DE]: 'https://lookiero.de',
  [SITE.ES]: 'https://lookiero.es',
  [SITE.FR]: 'https://lookiero.fr',
  [SITE.IT]: 'https://lookiero.it',
  [SITE.PT]: 'https://lookiero.pt',
  [SITE.UK]: 'https://lookiero.co.uk',
  [SITE.AT]: 'https://lookiero.at',
  [SITE.NL]: 'https://lookiero.nl',
  [SITE.SE]: 'https://lookiero.se',
  [SITE.CH]: 'https://lookiero.ch',
} as const;

const STORE_FALLBACK = 'unknown';
const STORE_KEY = 'store';

export {COUNTRY, LANGUAGE, HOSTNAME, NATIVE_STORE_ID, STORE_FALLBACK, STORE_KEY, SITE};
