// TODO should this be an enum?
export const OrderStatus = {
  PEDIDO_REALIZADO: 'PEDIDO_REALIZADO',
  EN_MARCHA: 'EN_MARCHA',
  BORRADOR: 'BORRADOR',
  EN_PREPARACION: 'EN_PREPARACION',
  ENVIADO: 'ENVIADO',
  ENTREGADO: 'ENTREGADO',
  COMPLETADO: 'COMPLETADO',
  LIQUIDADO: 'LIQUIDADO',
  RETORNADO: 'RETORNADO',
} as const;
