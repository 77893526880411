import {useMemo} from 'react';
import {useOverallInfo, useSettings, useShop} from '@components/_context';
import {MenuEntry, Link, LINKS} from './Links';
import {useApp} from '@app';
import {SITE} from '@domain/model/Site/Site';
import {Platform} from 'react-native';

const SITES_WITHOUT_BLOG: string[] = [SITE.SE, SITE.CH];

const LEAK_DATE = new Date(2024, 7, 6);
const SECURITY_INFORMATION_PATH: Record<string, string> = {
  ES: 'https://lookiero.es/blog/informacion-de-seguridad',
  FR: 'https://lookiero.fr/blog/informations-securite',
  IT: 'https://lookiero.it/blog/informazioni-di-sicurezza',
  UK: 'https://lookiero.co.uk/blog/security-information',
  DE: 'https://lookiero.de/blog/informationen-zur-sicherheit',
  AT: 'https://lookiero.at/blog/informationen-zur-sicherheit',
  NL: 'https://lookiero.nl/blog/veiligheidsinformatie',
  SE: 'https://lookiero.co.uk/blog/security-information',
  PT: 'https://lookiero.pt/blog/informasao-de-seguransa',
  BE: 'https://lookiero.be/blog/informations-securite',
  LU: 'https://lookiero.be/blog/informations-securite',
};

const SECURITY_LINK: Link = LINKS[MenuEntry.SECURITY_INFO];

export const useMenuLinks = (): {isLoading: boolean; links: Link[]} => {
  const {authorization} = useApp();
  const {site = '', loading: settingsLoading} = useSettings();
  const {canAccess: shopAccess, contextLoading: shopLoading} = useShop();
  const {dateCreated} = useOverallInfo();

  const isLoggedIn = Boolean(authorization);
  const isLoading = !site || settingsLoading || (isLoggedIn && shopLoading);
  const isBlogAvailable = site && !SITES_WITHOUT_BLOG.includes(site);
  const isShopAvailable = isLoggedIn && shopAccess;
  const securityInfo = authorization && Platform.OS === 'web' && dateCreated && new Date(dateCreated) < LEAK_DATE;

  return useMemo(() => {
    if (isLoading) {
      return {
        isLoading: true,
        links: [],
      };
    }
    const securityLinkPath = SECURITY_INFORMATION_PATH[site] || SECURITY_INFORMATION_PATH['UK'];

    const links = [
      ...(isShopAvailable ? [LINKS[MenuEntry.SHOP]] : []),
      ...(isLoggedIn ? [LINKS[MenuEntry.STYLE], LINKS[MenuEntry.ACCOUNT]] : []),
      ...(isBlogAvailable ? [LINKS[MenuEntry.BLOG]] : []),
      LINKS[MenuEntry.GIFTCARD],
      LINKS[MenuEntry.HELP],
      LINKS[MenuEntry.TERMS],
      ...(securityInfo ? [{...SECURITY_LINK, path: securityLinkPath}] : []),
    ];

    return {
      isLoading: false,
      links: links,
    };
  }, [isLoading, isShopAvailable, isLoggedIn, isBlogAvailable, securityInfo, site]);
};
