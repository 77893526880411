import gql from 'graphql-tag';

import {ORDER_FIELDS} from '@infra/api/graphQL/domain/model/Order/OrderFields';
import {ACCOUNT_FIELDS} from '@infra/api/graphQL/domain/model/Account/AccountFields';

const USER_SUMMARY_FIELDS = gql`
  query getUserSummary {
    user {
      id,
      country
      dateCreated
      username
      balance
      personalCode
      personalShopper {
        name
      }
      referral {
        name
        reward
      }
      hasAnyOrders
      card {
        cardLastNumbers
      }
      billingAddress {
        address {
          ${ACCOUNT_FIELDS.addressFields}
        }
      }
      shippingAddress {
        address {
          ${ACCOUNT_FIELDS.addressFields}
        }
        delivery {
          ${ACCOUNT_FIELDS.deliveryFields}
        }
      }
      subscription {
        modality
        lkDay
        cancelDuration
      }
    }
    ordersV2 {
      services {
        ${ORDER_FIELDS.serviceFields}
        shippingAddress {
          address {
            ${ORDER_FIELDS.shippingAddressFields}
          }
          delivery {
            ${ORDER_FIELDS.deliveryFields}
          }
          collect {
            ${ORDER_FIELDS.collectFields}
          }
        }
        checkout {
          ${ORDER_FIELDS.checkoutFields}
        }
      }
    }
  }
`;

export {USER_SUMMARY_FIELDS};
