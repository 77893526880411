import React from 'react';
import {useIntl} from 'react-intl';
import {Text} from '@lookiero/aurora';

import {useSettings} from '@components/_context/SettingsContext';
import {amountToAbsoluteAndSign, getOps} from './Currency.controller';
import {currencyDisplayName, currencyPropTypes} from './Currency.definition';

import {formatCurrency} from '../formatCurrency';

const Currency = ({amount, plus = false, ...props}) => {
  const {formatNumber} = useIntl();
  const {currency} = useSettings();
  const {sign, absoluteAmount} = amountToAbsoluteAndSign(amount, plus);

  return (
    <Text {...props}>
      {sign}
      {formatCurrency(formatNumber(absoluteAmount, getOps(absoluteAmount)), currency)}
    </Text>
  );
};

Currency.displayName = currencyDisplayName;

Currency.propTypes = currencyPropTypes;

export {Currency};
