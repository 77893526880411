import {AuthApplication} from '@application/Auth';
import {eventManager} from '@application/Event';
import {LocationApplication} from '@application/Site';
import {UiEvent, UserEvent} from '@domain/model/Event';
import {PageEventView, PageViewEventPayload, UserInfoPayload} from '@domain/model/Event/domain/model/UiEvents';
import {getAuthHeader} from '@infra/api/authManager/authManager';
import {getPaymentInstrument} from '@lookiero/payments-front';
import {emitUserEvent, initTracking, setTrackingUserId, unsetTrackingUserId} from '@lookiero/user-tracking-front';
import {DIRECT_BUY_EVENT} from '@lookiero/direct-buy-front';

import {
  APP_ACTIONS,
  DIRECT_BUY_ACTIONS,
  EXPERIMENT_ACTIONS,
  HOME_VIEWS,
  NAVIGATION_ACTIONS,
  NAVIGATION_ACTION_TO_NAME,
  SESION_ACTION_TO_NAME,
  SESSION_ACTIONS,
  TRANSACTIONAL_ACTIONS,
  TRANSACTIONAL_ACTION_TO_NAME,
  VIEW_TO_SECTION,
  PROMO_CODE_ACTIONS,
  MY_LOOKIERO_EVENTS,
} from './tracking.definition';
import {DomainEvent} from '../typings/domainEvent';

const getEvent = (
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {promoCode, isFirstOrder, deliveryMethod = '', daysSkipped, ...others}: any,
): Record<string, unknown> => {
  return {
    ecommerce: {
      [type]: {
        actionField: {
          coupon: promoCode,
          ...others,
        },
      },
    },
    isFirstOrder,
    daysSkipped,
    deliveryMethod,
  };
};
type SubscriptionPausedPayload = {daysPaused: number};

const PAYLOAD_TO_EVENT: Record<string, (payload: unknown) => Record<string, unknown>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.ORDER_CANCELLED]: (payload: any) => getEvent('cancel', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.ORDER_PLACED]: (payload: any) => getEvent('purchase', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.ORDER_SKIPPED]: (payload: any) => getEvent('orderSkipped', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.CHECKOUT]: (payload: any) => getEvent('checkout', payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [UserEvent.SUBSCRIPTION_CANCELLED]: (payload: any) => getEvent('checkout', payload),
  [UserEvent.SUBSCRIPTION_PAUSED]: (payload: unknown) => ({
    daysSkipped: (payload as SubscriptionPausedPayload).daysPaused,
  }),
};

const {userTracker, sendTrackerModel} = initTracking();

eventManager.subscribe(
  UiEvent.PAGE_LOAD,
  async ({view, category, ...payloadExtraInfo}: PageViewEventPayload): Promise<void> => {
    const section = VIEW_TO_SECTION[view];
    if (section) {
      const store = await LocationApplication.getStore();
      const paymentInstrument = await getPaymentInstrument();
      const userLogged = await AuthApplication.isLoggedIn();
      let userId = undefined;

      if (userLogged) {
        const {id} = (await AuthApplication.getLoginInfo()) || {};
        userId = id;
      }

      const eventData = {
        paymentMethod: paymentInstrument != null ? paymentInstrument.payment_method : 'none',
        paymentRegistered: paymentInstrument ? 'true' : 'false',
        section: category ? `${category}_${section}` : section,
        store,
        userLogged,
        ...payloadExtraInfo,
        ...(!!userId && {userId}),
      };

      userTracker(eventData);
    }
  },
);

SESSION_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const isLogout = action === UserEvent.LOGOUT;
    const isLogin = action === UserEvent.LOGIN;
    const isRegister = action === UserEvent.REGISTER;

    let eventData = {
      event: SESION_ACTION_TO_NAME[action],
      eventCategory: 'account',
      userLogged: action !== UserEvent.LOGOUT,
      ...payload,
    };

    if (isLogin || isRegister) {
      const {loginMethod, id} = await AuthApplication.getLoginInfo();

      setTrackingUserId(id.toString());
      eventData = {...eventData, userId: id, accessVia: loginMethod};
    } else if (isLogout) {
      unsetTrackingUserId();
    }
    emitUserEvent({...eventData});
  });
});

const getUserInfo = ({userFirstName, userLastName, userCity, userPhone, userZip}: UserInfoPayload): UserInfoPayload => {
  return {
    userFirstName,
    userLastName,
    userCity,
    userPhone,
    userZip,
  };
};

TRANSACTIONAL_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const paymentInstrument = await getPaymentInstrument();
    const extra =
      action === UserEvent.ORDER_PLACED
        ? {
            paymentMethod: paymentInstrument != null ? paymentInstrument.payment_method : 'none',
          }
        : {};

    const eventData = {
      event: TRANSACTIONAL_ACTION_TO_NAME[action],
      eventCategory: 'ecommerce',
      ...(action === UserEvent.CHECKOUT ? getUserInfo(payload) : {}),
      ...PAYLOAD_TO_EVENT[action](payload),
      ...extra,
    };
    emitUserEvent(eventData);
  });
});

interface MyLookieroEventPayload {
  section?: string;
  view: PageEventView;
  [x: string]: unknown;
}

MY_LOOKIERO_EVENTS.forEach(event =>
  eventManager.subscribe(event, async (payload: MyLookieroEventPayload) => {
    const [, eventName] = event.split('.');

    const {id: userId} = await AuthApplication.getLoginInfo();
    const store = await LocationApplication.getStore();
    const {view, ...rest} = payload;
    const section = rest.section || VIEW_TO_SECTION[view];

    userTracker({
      event: eventName,
      eventCategory: 'navigation',
      userLogged: true,
      userId,
      section,
      store,
      ...rest,
    });
  }),
);

eventManager.subscribe(
  UserEvent.SUBSCRIPTION_UPDATED,
  async ({subscription, newSubscription, previousFrequencyDay, currentFrequencyDay, userId}) => {
    if (subscription !== newSubscription) {
      emitUserEvent({
        event: 'updateSubscription',
        eventCategory: 'ecommerce',
        subscription,
        newSubscription,
        userId,
      });
    }
    if ((currentFrequencyDay || previousFrequencyDay) && currentFrequencyDay !== previousFrequencyDay) {
      emitUserEvent({
        event: 'recurrentDeliveryChanged',
        eventCategory: 'ecommerce',
        previousDay: previousFrequencyDay,
        newDay: currentFrequencyDay,
        userId,
      });
    }
  },
);

NAVIGATION_ACTIONS.forEach(action => {
  eventManager.subscribe(action, payload => {
    const eventData = {
      event: NAVIGATION_ACTION_TO_NAME[action],
      eventCategory: 'navigation',
      ...payload,
    };
    emitUserEvent(eventData);
  });
});

eventManager.subscribe(UiEvent.PAGE_LOAD, async ({view}: PageViewEventPayload): Promise<void> => {
  if (HOME_VIEWS.includes(view)) {
    sendTrackerModel({...(await getAuthHeader())});
  }
});

EXPERIMENT_ACTIONS.forEach(action => {
  eventManager.subscribe(action, payload => {
    const eventData = {
      event: action,
      eventCategory: 'experiment',
      ...payload,
    };
    emitUserEvent(eventData);
  });
});

DIRECT_BUY_ACTIONS.forEach(action => {
  eventManager.subscribe(action as DomainEvent, async payload => {
    const isSoldOut = action === DIRECT_BUY_EVENT.CHECKOUT_SOLD_OUT;
    const isCompleteYourLookSelected = action === DIRECT_BUY_EVENT.COMPLETE_YOUR_LOOK_SELECTED;
    const isCategoriesSelected = action === DIRECT_BUY_EVENT.CATEGORIES_SELECTED;
    const isCategoriesLoaded = action === DIRECT_BUY_EVENT.CATEGORIES_LOAD;
    const isCategoryTabSelected = action === DIRECT_BUY_EVENT.CATEGORY_TAB_SELECTED;
    const isWishlistItemAdded = action === DIRECT_BUY_EVENT.WISHLIST_ITEM_ADDED;
    const isWishlistItemRemoved = action === DIRECT_BUY_EVENT.WISHLIST_ITEM_REMOVED;
    const isShopButtonClicked = action === DIRECT_BUY_EVENT.SHOP_BUTTON_CLICKED;
    const isEmptyStateShowed = action === DIRECT_BUY_EVENT.EMPTY_STATE;
    const viewWishlist = action === DIRECT_BUY_EVENT.VIEW_WISHLIST;
    const showNotifyStockButton = action === DIRECT_BUY_EVENT.SHOW_NOTIFY_STOCK_BUTTON;
    const notifyStockButtonClicked = action === DIRECT_BUY_EVENT.NOTIFY_STOCK_BUTTON_CLICKED;
    const closeStockOutModal = action === DIRECT_BUY_EVENT.CLOSE_STOCK_OUT_MODAL;
    const removeAndPayStockOutModal = action === DIRECT_BUY_EVENT.REMOVE_AND_PAY_STOCK_OUT_MODAL;
    const showStockOutModal = action === DIRECT_BUY_EVENT.SHOW_STOCK_OUT_MODAL;
    const lookAndLikeBanner = action === DIRECT_BUY_EVENT.LOOK_AND_LIKE_BANNER_CLICKED;
    const shopEditShippingfInfo = action === DIRECT_BUY_EVENT.SHOP_EDIT_SHIPPING_INFO;
    const isRefreshinAccessesAfterCheckout = action === DIRECT_BUY_EVENT.AFTER_CHECKOUT_ACCESS_GRANTED;
    const isRedirectToCategoriesFromCYL = action === DIRECT_BUY_EVENT.REDIRECT_TO_CATEGORIES_FROM_CYL;
    const anchorItemList = action === DIRECT_BUY_EVENT.ANCHOR_ITEM_LIST;

    if (
      isSoldOut ||
      isCategoriesSelected ||
      isCategoriesLoaded ||
      isCompleteYourLookSelected ||
      isCategoryTabSelected ||
      isWishlistItemAdded ||
      isWishlistItemRemoved ||
      isShopButtonClicked ||
      viewWishlist ||
      showNotifyStockButton ||
      notifyStockButtonClicked ||
      closeStockOutModal ||
      removeAndPayStockOutModal ||
      showStockOutModal ||
      lookAndLikeBanner ||
      shopEditShippingfInfo ||
      isRefreshinAccessesAfterCheckout ||
      isEmptyStateShowed ||
      isRedirectToCategoriesFromCYL ||
      anchorItemList
    ) {
      const store = await LocationApplication.getStore();
      const userLogged = await AuthApplication.isLoggedIn();
      let userId = undefined;

      if (userLogged) {
        const {id} = (await AuthApplication.getLoginInfo()) || {};
        userId = id;
      }
      payload['store'] = store;
      payload['userId'] = userId;
    }

    const eventData = {
      event: action,
      eventCategory: 'shop',
      ...payload,
    };
    emitUserEvent(eventData);
  });
});

APP_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const {id: userId} = await AuthApplication.getLoginInfo();
    const eventData = {
      event: action,
      eventCategory: 'app',
      userId,
      ...payload,
    };
    emitUserEvent(eventData);
  });
});

PROMO_CODE_ACTIONS.forEach(action => {
  eventManager.subscribe(action, async payload => {
    const eventData = {
      event: action,
      eventCategory: 'ecommerce',
      ...payload,
    };
    emitUserEvent(eventData);
  });
});
