export enum UserEvent {
  LOGIN = 'user.login',
  REGISTER = 'user.register',
  LOGOUT = 'user.logout',
  CHECKOUT = 'user.checkout',
  ORDER_CANCELLED = 'user.order-cancelled',
  ORDER_PLACED = 'user.order-placed',
  ORDER_SKIPPED = 'user.order-skipped',
  SUBSCRIPTION_CANCELLED = 'user.subscription-cancelled',
  SUBSCRIPTION_UPDATED = 'user.subscription-updated',
  SUBSCRIPTION_PAUSED = 'user.subscription-paused',
}
