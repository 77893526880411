const AFTER = 'after';

const currencyConfig = {
  EUR: {
    position: AFTER,
    symbol: '€',
  },
  GBP: {
    position: 'before',
    symbol: '£',
  },
  SEK: {
    position: AFTER,
    symbol: 'kr',
  },
  CHF: {
    position: AFTER,
    symbol: 'CHF',
  },
};

const getConfig = currency => currencyConfig[currency] || currencyConfig['EUR'];

export {getConfig, AFTER};
