import PropTypes from 'prop-types';

const currencyDisplayName = 'Currency';

const currencyPropTypes = {
  amount: PropTypes.number.isRequired,
  plus: PropTypes.bool,
};

export {currencyDisplayName, currencyPropTypes};
