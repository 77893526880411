import {getAppBoyInitialUrl} from './helpers/getInitialUrl';
import {BASENAME} from '../../modules/routing/routes.definition';
import React, {FC, useCallback, useLayoutEffect, useRef} from 'react';
import {EmitterSubscription, Linking} from 'react-native';
import {useNavigate} from 'react-router-native';

const TOP_LEVEL_DOMAIN = ['be', 'co.uk', 'de', 'es', 'fr', 'it', 'pt', 'at', 'nl', 'se', 'ch'];
const URL_SCHEME = 'lookiero://';

// The process.env has to be called in a function because Android does not resolve it correctly
const getHostname = (): string =>
  process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? 'lookiero' : 'web2.dev.aws.lookiero';
const getWebDomains = (): string[] => TOP_LEVEL_DOMAIN.map(tld => `https://${getHostname()}.${tld}${BASENAME}`);

const linkingPrefixes = [URL_SCHEME, ...getWebDomains()];

interface LinkingEvent {
  readonly url: string;
}
/**
 * ⚠️ This component should not be used as a child of any component that can be disassembled
 * and reassembled, since AppLinking does not support rerenders and every time it is mounted it
 * executes the useLayoutEffect and this causes the navigation to not work correctly if any deep has
 * been used to open or redirect to the app.
 */
const AppLinking: FC = () => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;

  const openUrl = useCallback((event: LinkingEvent) => {
    if (!event || !event.url) {
      return;
    }

    const url = decodeURIComponent(event.url);

    const prefix = linkingPrefixes.find(linkingPrefix => url.includes(linkingPrefix));
    const route = url.replace(prefix || '', '');
    const rest = removeUserPrefixFromPath(route);

    navigateRef.current(rest.startsWith('/') ? rest : `/${rest}`);
  }, []);

  useLayoutEffect(() => {
    let listener: EmitterSubscription | null;
    const startLinking = async (): Promise<void> => {
      listener = Linking.addEventListener('url', openUrl);

      const url = (await getAppBoyInitialUrl()) || (await Linking.getInitialURL());

      if (!url) {
        return;
      }

      openUrl({url});
    };

    startLinking();

    return () => (listener ? listener.remove() : undefined);
  }, [openUrl]);

  return <></>;
};

export function removeUserPrefixFromPath(path: string): string {
  return /^\/*user$/.test(path) ? '/' : path.replace(/^\/*user\//, '/');
}

export {AppLinking};
