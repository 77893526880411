type GetOps = {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};
const getOps = (amount: number): GetOps => {
  const fractionDigits = amount % 1 === 0 ? 0 : 2;
  return {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };
};

type AmountToAbsoluteAndSign = {
  sign: '' | '-' | '+';
  absoluteAmount: number;
};
const amountToAbsoluteAndSign = (amount: number, plus: boolean): AmountToAbsoluteAndSign => ({
  sign: plus ? '+' : Math.sign(amount) >= 0 ? '' : '-',
  absoluteAmount: Math.abs(amount),
});

export {amountToAbsoluteAndSign, getOps};
