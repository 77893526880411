import React, {FC, Suspense} from 'react';
import {Platform} from 'react-native';
import {useLocation, Routes as ReactRouterRoutes, Route as ReactRouterRoute, Navigate} from 'react-router-native';
import {View} from '@lookiero/aurora';
import {useApp} from '@app';
import {useWebview, Webview} from '../../../ui/app/components/Webview';
import {ROUTES} from '../routes';
import {NavigateToFallback} from './NavigateToFallback';
import {Path, Route, RoutesConfig} from '../routes.definition';

const routes: {
  readonly publicRoutes: Partial<RoutesConfig>;
  readonly privateRoutes: Partial<RoutesConfig>;
} = Object.entries<Route>(ROUTES).reduce(
  (acc, [key, route]) => ({
    publicRoutes: {...acc.publicRoutes, ...(route.authorization ? {} : {[key]: route})},
    privateRoutes: {...acc.privateRoutes, ...(route.authorization ? {[key]: route} : {})},
  }),
  {publicRoutes: {}, privateRoutes: {}},
);

const {publicRoutes, privateRoutes} = routes;

const WEB_REDIRECT_PREFIX = '/web_redirect';
const IS_WEB = Platform.OS === 'web';

const Routes: FC = () => {
  const {authorization, isFirstLogin} = useApp();
  const {link} = useWebview();

  if (!IS_WEB && link) return <Webview link={link} />;

  if (authorization === undefined) return <View />;

  return (
    <Suspense fallback={<View />}>
      <ReactRouterRoutes>
        {Object.entries(authorization ? privateRoutes : publicRoutes).map(([key, {path, component: Component}]) => (
          <ReactRouterRoute key={key} path={path} element={<Component />} />
        ))}

        {IS_WEB && <ReactRouterRoute path={`${WEB_REDIRECT_PREFIX}/*`} element={<RewriteWebRedirectUrl />} />}

        <ReactRouterRoute
          path="*"
          element={
            <NavigateToFallback
              authorisedFallback={Path.HOME}
              unauthorisedFallback={!IS_WEB && isFirstLogin ? Path.INTRO : Path.LOGIN}
              replaceOnNavigate={IS_WEB}
            />
          }
        />
      </ReactRouterRoutes>
    </Suspense>
  );
};

const RewriteWebRedirectUrl: FC = () => {
  const {pathname, search, state} = useLocation();
  const webRedirectRewrittenPathName = (pathname || '').replace(WEB_REDIRECT_PREFIX, '');
  return <Navigate to={{pathname: webRedirectRewrittenPathName, search}} state={state} replace />;
};

export {Routes};
